// export const BASE_URL = "http://104.211.88.87/adminapi/";
// export const APP_BASE_URL = "http://104.211.88.87/vendorapi/";
// export const CLIENT_BASE_URL = "http://104.211.88.87/clientapi/";
// export const VENDOR_SERVICE_OFFERED ="http://104.211.88.87/vendorapi/v1/getServiceOffered";
// export const VENDOR_IDENTIFICATION_DOCS = "http://104.211.88.87/vendorapi/v1/getVendorIdentificationDoc";
// export const IMAGE_URL = "http://104.211.88.87/uploadapi/v1/imageupload";
// export const IMAGE_STORE_PATH = "http://104.211.88.87/uploadapi/images/";
// export const IMAGE_PATH_ONLY = "http://104.211.88.87/uploadapi";
// export const INVOICE_PATH_ONLY = "http://104.211.88.87/adminapi";
// ====================================================
/* current UAT IP = 20.235.125.147 */

export const BASE_URL = "https://7clingo.claritus.in/adminapi/";
export const APP_BASE_URL = "https://7clingo.claritus.in/vendorapi/";
export const CLIENT_BASE_URL = "https://7clingo.claritus.in/clientapi/";
export const VENDOR_SERVICE_OFFERED = "https://7clingo.claritus.in/vendorapi/v1/getServiceOffered";
export const VENDOR_IDENTIFICATION_DOCS = "https://7clingo.claritus.in/vendorapi/v1/getVendorIdentificationDoc";
export const IMAGE_URL = "https://7clingo.claritus.in/uploadapi/v1/imageupload";
export const IMAGE_STORE_PATH = "https://7clingo.claritus.in/uploadapi/images/";
export const IMAGE_PATH_ONLY = "https://7clingo.claritus.in/uploadapi";
export const INVOICE_PATH_ONLY = "https://7clingo.claritus.in/adminapi";
export const SOCKET_PATH = "https://7clingo.claritus.in";
export const SOCKET_MUSIC = "https://7clingo.claritus.in/adminapi/callMusic/ring3.wav";
export const LOCAL_HOST = "https://7clingo.claritus.in";

export const PROD = true;
// export const LOCAL_HOST = "http://localhost:3000";

/* ................... 7C Current Staging ...................... */

// export const BASE_URL = "https://7clingostaging.claritus.in/adminapi/";
// export const APP_BASE_URL = "https://7clingostaging.claritus.in/vendorapi/";
// export const CLIENT_BASE_URL = "https://7clingostaging.claritus.in/clientapi/";
// export const VENDOR_SERVICE_OFFERED = "https://7clingostaging.claritus.in/vendorapi/v1/getServiceOffered";
// export const VENDOR_IDENTIFICATION_DOCS = "https://7clingostaging.claritus.in/vendorapi/v1/getVendorIdentificationDoc";
// export const IMAGE_URL = "https://7clingostaging.claritus.in/uploadapi/v1/imageupload";
// export const IMAGE_STORE_PATH = "https://7clingostaging.claritus.in/uploadapi/images/";
// export const IMAGE_PATH_ONLY = "https://7clingostaging.claritus.in/uploadapi";
// export const INVOICE_PATH_ONLY = "https://7clingostaging.claritus.in/adminapi";
// export const SOCKET_PATH = "https://7clingostaging.claritus.in";
// export const SOCKET_MUSIC = "https://7clingostaging.claritus.in/adminapi/callMusic/ring3.wav";
// export const LOCAL_HOST = "https://7clingostaging.claritus.in";



/* ................... 7C QA Server ...................... */

// export const BASE_URL = "https://7clingoqa.claritus.in/adminapi/";
// export const APP_BASE_URL = "https://7clingoqa.claritus.in/vendorapi/";
// export const CLIENT_BASE_URL = "https://7clingoqa.claritus.in/clientapi/";
// export const VENDOR_SERVICE_OFFERED = "https://7clingoqa.claritus.in/vendorapi/v1/getServiceOffered";
// export const VENDOR_IDENTIFICATION_DOCS = "https://7clingoqa.claritus.in/vendorapi/v1/getVendorIdentificationDoc";
// export const IMAGE_URL = "https://7clingoqa.claritus.in/uploadapi/v1/imageupload";
// export const IMAGE_STORE_PATH = "https://7clingoqa.claritus.in/uploadapi/images/";
// export const IMAGE_PATH_ONLY = "https://7clingoqa.claritus.in/uploadapi";
// export const INVOICE_PATH_ONLY = "https://7clingoqa.claritus.in/adminapi";
// export const SOCKET_PATH = "https://7clingoqa.claritus.in";
// export const SOCKET_MUSIC = "https://7clingoqa.claritus.in/adminapi/callMusic/ring3.wav";
// export const LOCAL_HOST = "https://7clingoqa.claritus.in";

// =======================================================================

// export const BASE_URL = "http://52.201.119.41:8282/api/";
// export const APP_BASE_URL = "http://52.201.119.41:8283/api/";
// export const CLIENT_BASE_URL = "http://52.201.119.41:8284/api/"; // for client New api url
// export const VENDOR_SERVICE_OFFERED = "http://52.201.119.41:8283/api/v1/getServiceOffered";
// export const VENDOR_IDENTIFICATION_DOCS = "http://52.201.119.41:8283/api/v1/getVendorIdentificationDoc";
// export const IMAGE_URL = "http://52.201.119.41:3001/api/v1/imageupload";
// export const IMAGE_STORE_PATH = "http://52.201.119.41:3001/images/";
// export const IMAGE_PATH_ONLY = "http://52.201.119.41:3001";
// export const INVOICE_PATH_ONLY = "http://52.201.119.41:8282";
// export const SOCKET_PATH = "http://52.201.119.41:8282";


// export const BASE_URL = "https://demo.lykstage.com:8282/api/";
// export const APP_BASE_URL = "https://demo.lykstage.com:8283/api/";
// export const CLIENT_BASE_URL = "https://demo.lykstage.com:8284/api/"; // for client New api url
// export const VENDOR_SERVICE_OFFERED = "https://demo.lykstage.com:8283/api/v1/getServiceOffered";
// export const VENDOR_IDENTIFICATION_DOCS = "https://demo.lykstage.com:8283/api/v1/getVendorIdentificationDoc";
// export const IMAGE_URL = "https://demo.lykstage.com:3001/api/v1/imageupload";
// export const IMAGE_STORE_PATH = "https://demo.lykstage.com:3001/images/";
// export const IMAGE_PATH_ONLY = "https://demo.lykstage.com:3001";
// export const INVOICE_PATH_ONLY = "https://demo.lykstage.com:8282";
// export const SOCKET_PATH = "https://demo.lykstage.com:8282";
// export const SOCKET_MUSIC = "https://7clingo.claritus.in/adminapi/callMusic/ring3.wav";
// export const LOCAL_HOST = "https://demo.lykstage.com:8084";
