import React from "react";
import "./globalchat.css";
import { Link } from "react-router-dom";
import { AlertMessage, ImageName } from "../../enums";
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import { MultiSelectBox, SelectBox } from "../Admin/SharedComponents/inputText";
import { consoleLog } from "../../services/common-function";
import { ApiCall } from "../../services/middleware";
import { ErrorCode, UsersEnums } from "../../services/constant";
import { Decoder } from "../../services/auth";
import MainChatPage from "../ChatPages/Mainpage";
import GlobalChatList from "./GlobalChatList";
import { toast } from "react-toastify";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { MdDelete } from "react-icons/md";


export default class GlobalChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userType: "",
            allNewUsers: [],
            loader: false,
            newChatModal: false,
            selectedNewUser: [],
            chatGroup: [],
            chatSingleUser: [],
            selectedChatGroupValue: '',
            myId: "",
            isGroup: 0,
            chatParticipantModal: false,
            groupChatParticipant: [],
            addGrouoChatParticipantModal: false,
            chatParticipants: [],
            selectedAddGroupChatParticipantValue: [],
        }

        this.load();
    }

    loaderChange = async (type) => {
        this.setState({
            loader: type
        })
    }

    load = async () => {
        window.scrollTo(0, 0);
        let authData = localStorage.getItem("AuthToken");
        let authUser = Decoder.decode(authData);
        // console.log("Auth User >>> ", authUser)
        let activeUsers = [];

        let res = await ApiCall("getUsers", {});
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {

            let payload = Decoder.decode(res.data.payload);
            // consoleLog("All Chat users >>>>>>>>>>> ", payload.data);
            if (payload.data.length > 0) {
                for (let user of payload.data) {
                    activeUsers.push({
                        label: user.label === undefined ? user.userName : user.label,
                        value: user.id,
                        userId: user.userId,
                        userTypeId: user.userTypeId,
                        clientNameForContact: user.clientNameForContact,
                        userName: user.userName
                    })
                }
            }
        }
        await this.loaderChange(true);
        this.setState({
            allNewUsers: activeUsers,
            myId: authUser.data.userid,
            userType: authUser.data.usertypeid,
            selectedChatGroupValue: "",
            isGroup: 0
        })
        await this.loaderChange(false);
        this.fetchChatGroup();
    }

    openNewChatModal = () => {
        this.setState({
            newChatModal: true
        })
    }

    closeNewChatModal = () => {
        this.setState({
            newChatModal: false,
            selectedNewUser: []
        })
    }

    selectedNewUserChange = (value) => {
        // console.log("New User Add >>> ", value)
        this.setState({
            selectedNewUser: value
        })
    }

    selectedNewUserSingleChange = (value) => {
        // console.log("New User Add Single >>> ", value)
        let arr = [];
        arr.push(value);
        this.setState({
            selectedNewUser: arr
        })
    }

    startChatWithNewUser = async () => {
        let reqData = {
            participants: this.state.selectedNewUser
        }
        // consoleLog("req of start Chat ", reqData)
        let res = await ApiCall('startGlobalChat', reqData);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            // consoleLog("Selected Chat users >>>>>>>>>>> ", payload.data);
            await this.fetchChatGroup();


            if (this.state.selectedNewUser.length > 1) {
                this.state.chatGroup.map((ch) => {
                    if (ch.value._id == payload.data.id) {
                        this.setState({
                            selectedChatGroupValue: ch.value
                        })
                        this.showChatPage(ch.value)
                    }
                })
                this.setState({
                    isGroup: 1
                })
            } else {
                this.state.chatSingleUser.map((ch) => {
                    if (ch.value._id == payload.data.id) {
                        this.setState({
                            selectedChatGroupValue: ch.value
                        })
                        this.showChatPage(ch.value)
                    }
                })
                this.setState({
                    isGroup: 0
                })
            }

        }

        this.closeNewChatModal()
    }

    fetchChatGroup = async () => {

        let res = await ApiCall("getGlobalChatList", {});
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            let payload = Decoder.decode(res.data.payload);
            // consoleLog("Global Chat List", payload.data);
            let arr = [],
                singleUserArr = [];
            if (payload.data.length > 0) {
                payload.data.map(async (item, i) => {
                    // if(item.participantId.indexOf(this.state.myId) !== -1){}
                    if (item.participantId.length > 2) {

                        let obj = {
                            label: item.groupName,
                            value: item
                        }
                        obj.value['label'] = item.groupName;
                        obj.value['isGroup'] = 1;
                        arr.push(obj);
                        await this.loaderChange(true);
                        this.setState({
                            chatGroup: arr
                        })
                        await this.loaderChange(false);
                    } else {
                        let groupName = "";
                        item.participantId.map((pt, i) => {
                            if (pt == this.state.myId) {
                                if (i == 0) {
                                    groupName = item.participantName[1]
                                } else {
                                    groupName = item.participantName[0]
                                }
                            }
                        })
                        let obj = {
                            label: groupName,
                            value: item
                        }
                        obj.value['label'] = groupName;
                        obj.value['isGroup'] = 0;
                        singleUserArr.push(obj);
                        await this.loaderChange(true);
                        this.setState({
                            chatSingleUser: singleUserArr
                        })
                        await this.loaderChange(false);
                    }

                })
            }
        } else {
            toast.error(res.message);
        }
    }


    showChatPage = (value) => {
        return value !== "" ? <>
            {this.state.isGroup === 1 ?
                <div className="prticipants-area _fl ad_participant_row">
                    <div className="row">
                        <div className="col-md-6">
                            {/* <h3><span style={{ cursor: "pointer" }} onClick={() => this.openChatParticipantModal()}>{value.participantId.length} Participant</span></h3> */}
                        </div>

                        <div className="col-md-6">
                            <a href="javascript:void(0)" className="add-part-btn" onClick={this.openAddGroupChatParticipantModal}>
                                + Add Participants
                            </a>
                        </div>
                    </div>
                </div> : <></>}
            <MainChatPage jobId={value._id} jobAlphaId={"0"}/>
        </> : <></>

    }

    changeChatGroup = (value) => {
        // console.log("Chat group Id >>> ", value)
        let data = localStorage.getItem("AuthToken");
        let authUser = Decoder.decode(data);
        // consoleLog("Group Value >>> ", value);
        let temp = [];
        for (let i = 0; i < value.participantId.length; i++) {
            if (authUser.data.userid !== value.participantId[i])
                temp.push({
                    label: value.participantName[i],
                    value: value.participantId[i],
                    actualId: value.participantActulaId[i]
                })
        }
        // consoleLog("Temp Arr >>> ", temp)
        let arr = [];

        // consoleLog("Group Chat Participants >>> ", temp);
        // consoleLog("All New Users >>> ", this.state.allNewUsers)
        this.state.allNewUsers.map((pp) => {
            if (temp.length > 0) {
                let found = false;
                for (let gp of temp) {
                    if (pp.value == gp.actualId) {
                        // console.log("Matched PP >>>> ", pp)
                        found = true;
                        break;
                    }
                }
                if (found === false) {
                    // consoleLog("PArtners >>> ", pp)
                    arr.push({
                        label: pp.label,
                        userName: pp.userName,
                        value: pp.value,
                        userTypeId: pp.userTypeId,
                        userId: pp.userId,
                        clientNameForContact: pp.clientNameForContact
                    });
                }
            } else {
                arr.push({
                    label: pp.label,
                    userName: pp.userName,
                    value: pp.value,
                    userTypeId: pp.userTypeId,
                    userId: pp.userId,
                    clientNameForContact: pp.clientNameForContact
                });
            }
        })
        this.setState({
            selectedChatGroupValue: value,
            groupChatParticipant: temp,
            chatParticipants: arr
        })
        this.showChatPage(value)
    }

    changeChatTab = async (value) => {
        if (value === "single") {
            await this.loaderChange(true);
            this.setState({
                isGroup: 0,
                selectedChatGroupValue: ""
            })
            await this.loaderChange(false);
        } else {
            await this.loaderChange(true);
            this.setState({
                isGroup: 1,
                selectedChatGroupValue: ""
            })
            await this.loaderChange(false);
        }
    }

    openChatParticipantModal = () => {
        this.setState({
            chatParticipantModal: true
        })
    }

    closeChatParticipantModal = () => {
        this.setState({
            chatParticipantModal: false
        })
    }

    removeParticipants = async (value) => {
        if (this.state.groupChatParticipant.length > 1) {
            let apiObj = {
                id: this.state.selectedChatGroupValue._id,
                participantName: value.label,
                participantId: value.value,
                participantActualId: value.actualId
            };
            // consoleLog("Remove Participants data req >>> ", apiObj)
            let res = await ApiCall("removeParticipantFromGroup", apiObj);
            if (
                res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
            ) {
                let payload = Decoder.decode(res.data.payload);
                this.load();
                this.closeChatParticipantModal();
                toast.success(AlertMessage.MESSAGE.CHAT.REMOVE_PARTICIPANTS);
            }
        } else {
            toast.error(AlertMessage.MESSAGE.CHAT.NOT_REMOVE_PARTICIPANTS)
        }

    }

    removeChatGroup = async (value) => {
        let req = {
            id: value._id
        }
        // consoleLog("Remove Req >>> ", req);
        let res = await ApiCall("removeChatGroup", req);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            toast.success(AlertMessage.MESSAGE.CHAT.REMOVE_CHAT);
            this.load();
        }
    }

    editGroupName = async (value) => {
        let req = {
            id: value.id,
            groupName: value.updatedGroupName
        }
        consoleLog("Update Group Details", req);
        let res = await ApiCall("updateGroupName", req);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            toast.success(AlertMessage.MESSAGE.CHAT.GROUP_NAME_UPDATE_SUCCESSFULLY);
            this.load();
        }
    }

    openAddGroupChatParticipantModal = () => {
        this.setState({
            addGrouoChatParticipantModal: true
        })
    }

    closeAddGroupChatParticipantModal = () => {
        this.setState({
            addGrouoChatParticipantModal: false
        })
    }

    selectAddGroupParticipants = (value) => {
        this.setState({
            selectedAddGroupChatParticipantValue: value
        })
    }

    submitParticipantsInGroup = async () => {

        consoleLog("Add Group Particpat >>> ", this.state.selectedAddGroupChatParticipantValue);
        let participantId = [],
            participantName = [],
            participantActualId = [];

        if (this.state.selectedAddGroupChatParticipantValue.length > 0) {
            this.state.selectedAddGroupChatParticipantValue.map((data) => {
                participantId.push(data.userId);
                participantActualId.push(data.value);
                participantName.push(data.userName)
            })
        }

        let reqObj = {
            id: this.state.selectedChatGroupValue._id,
            participantName: participantName,
            participantId: participantId,
            participantActualId: participantActualId
        }

        // consoleLog("Add Participnat in exist group req >> ", reqObj)
        let res = await ApiCall("addParticipantFromGroup", reqObj);
        if (
            res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
            res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
        ) {
            this.closeAddGroupChatParticipantModal();
            await this.fetchChatGroup();
            toast.success(AlertMessage.MESSAGE.CHAT.ADD_PARTICIPANTS);
            for (let ch of this.state.chatGroup) {
                if (ch._id == this.state.selectedChatGroupValue._id) {
                    this.changeChatGroup(ch);
                }
            }
            this.setState({
                selectedAddGroupChatParticipantValue: []
            })
        } else {
            toast.error(res.message)
        }
    }


    render() {
        return (
            <React.Fragment >
                <div className="component-wrapper">
                    <div
                        className="vn_frm"
                        style={{ color: "grey", paddingBottom: "2%" }}
                    >
                        {" "}
                        <Link to="/adminDashboard">Dashboard</Link> {" "}
                        / Chat
                    </div>

                    {/* {(this.state.userType === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN || this.state.userType === UsersEnums.APPLICATION_ROLE.ADMIN_STAFF) ? */}
                    <div className="row">
                        <div className="col-md-12">
                            <button type="button" className="new_chat_btn" onClick={() => this.openNewChatModal()}>
                                <img src={ImageName.IMAGE_NAME.MENU.CHAT_ICON_BLUE} />
                                <span>New Chat</span>
                            </button>
                        </div>
                    </div>
                    {/* : <></>
                     } */}
                    <div className=" pt_50">
                        <div className="row box_border">
                            <div className="col-md-3 g_chat">
                                <Tabs
                                    activeKey={this.state.isGroup === 0 ? "single" : "group"}
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                    onSelect={(k) => this.changeChatTab(k)}
                                >
                                    <Tab eventKey="single" title="Chat">
                                        {this.state.chatSingleUser.length > 0 ?
                                            <GlobalChatList
                                                setVal={this.state.selectedChatGroupValue === '' ? '' : this.state.selectedChatGroupValue.label}
                                                value={this.state.chatSingleUser}
                                                changeGroupValue={(value) => this.changeChatGroup(value)}
                                                deleteChatGroup={(value) => this.removeChatGroup(value)}
                                                editChatGroup={(value) => this.editGroupName(value)}
                                                userType={this.state.userType}
                                                isGroup={this.state.isGroup}
                                            /> :
                                            <></>
                                        }
                                    </Tab>
                                    <Tab eventKey="group" title="Group Chat">
                                        {this.state.chatGroup.length > 0 ?
                                            <GlobalChatList
                                                setVal={this.state.selectedChatGroupValue === '' ? '' : this.state.selectedChatGroupValue.label}
                                                value={this.state.chatGroup}
                                                changeGroupValue={(value) => this.changeChatGroup(value)}
                                                deleteChatGroup={(value) => this.removeChatGroup(value)}
                                                editChatGroup={(value) => this.editGroupName(value)}
                                                userType={this.state.userType}
                                                isGroup={this.state.isGroup}
                                            /> :
                                            <></>
                                        }
                                    </Tab>
                                </Tabs>
                            </div>
                            <div className="col-md-9 col-sm-9">
                                <div className="chat_header">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <span>{this.state.selectedChatGroupValue === '' ? 'New Chat' : this.state.selectedChatGroupValue.label}</span>
                                        </div>
                                        <div className="col-md-2">
                                            {this.state.isGroup === 1 ? this.state.selectedChatGroupValue === '' ? <></> :
                                                <span className="chat_circle" onClick={() => this.openChatParticipantModal()}>
                                                    {this.state.selectedChatGroupValue === '' ? "0" : (this.state.selectedChatGroupValue.participantId.length - 1)}
                                                </span> : <></>}
                                        </div>
                                    </div>
                                </div>
                                {this.showChatPage(this.state.selectedChatGroupValue)}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.newChatModal} >
                    <Modal.Header>
                        <Modal.Title>Start New Conversation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="web-form-bx">
                            <div className="frm-label">Participants *</div>
                            <div className="bts-drop">
                                {(this.state.userType === UsersEnums.APPLICATION_ROLE.CLIENT || this.state.userType === UsersEnums.APPLICATION_ROLE.REQUESTER
                                    || this.state.userType === UsersEnums.APPLICATION_ROLE.SUPERVISOR || this.state.userType === UsersEnums.APPLICATION_ROLE.VENDOR) ?
                                    <SelectBox
                                        optionData={this.state.allNewUsers}
                                        value={this.state.selectedNewUser}
                                        onSelectChange={(value) =>
                                            this.selectedNewUserSingleChange(value)
                                        }
                                    ></SelectBox>
                                    :
                                    <MultiSelectBox
                                        optionData={this.state.allNewUsers}
                                        value={this.state.selectedNewUser}
                                        onSelectChange={(value) =>
                                            this.selectedNewUserChange(value)
                                        }
                                    ></MultiSelectBox>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeNewChatModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.startChatWithNewUser}>
                            Start Chat
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.chatParticipantModal} onHide={this.closeChatParticipantModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Participants List</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="web-form-bx">
                            <div className="frm-label">Participants List</div>
                            <div className="row">
                                {this.state.groupChatParticipant.map((data, i) => <>
                                    <div className="col-md-10" key={i}>
                                        {data.label}
                                    </div>
                                    {(this.state.userType === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN || this.state.userType === UsersEnums.APPLICATION_ROLE.ADMIN_STAFF) ? <>
                                        {this.state.groupChatParticipant.length > 1 ?
                                            <div className="col-md-2">
                                                <MdDelete style={{ cursor: "pointer" }} onClick={() => this.removeParticipants(data)} />
                                            </div> : <></>}</> : <></>}
                                </>)}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeChatParticipantModal}>
                            Close
                        </Button>
                        {/* <Button variant="primary" onClick={this.submitParticipants}>
      Add
    </Button> */}
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.addGrouoChatParticipantModal} >
                    <Modal.Header>
                        <Modal.Title>Add Participants </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="web-form-bx">
                            <div className="frm-label">Participants *</div>
                            <div className="bts-drop">
                                <MultiSelectBox
                                    optionData={this.state.chatParticipants}
                                    value={this.state.selectedAddGroupChatParticipantValue}
                                    onSelectChange={(value) =>
                                        this.selectAddGroupParticipants(value)
                                    }
                                ></MultiSelectBox>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeAddGroupChatParticipantModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.submitParticipantsInGroup}>
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}